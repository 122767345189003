<template>
  <div>
    <v-card class="grey lighten-2">
      <v-row dense>
        <v-col
          cols="12"
          md="6"
          class="white pb-0"
        >
          <v-card-text class="pt-0 pb-0">
            <h2 class="h2 mb-4 mt-4">
              Generic Import
            </h2>
            <v-file-input
              outlined
              label="File to import"
              accept=".xls,.xlsx"
              v-model="fileToImport"
              :disabled="importing"
            />
            <v-select
              label="Sheet"
              outlined
              v-if="activeWorkbook !== null"
              v-model="activeSheet"
              :items="availableSheets"
              :disabled="importing"
            />
          </v-card-text>
          <v-divider
            class="mb-4"
            v-if="activeWorkbook !== null && activeSheet !== null"
          />
          <v-card-text
            class="pt-0 pb-0"
            v-if="activeWorkbook !== null && activeSheet !== null"
          >
            <div class="mb-3">
              Required Fields
            </div>
            <template v-for="(requiredField, index) in requiredFields">
              <v-select
                :key="requiredField.property"
                :label="requiredField.title"
                :items="availableValues"
                item-value="key"
                outlined
                hide-details
                class="mb-3"
                :disabled="importing"
                v-model="requiredFields[index].value"
                @input="fieldValueChanged"
              >
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <div>
                    <!-- HTML that describe how select should render items when the select is open -->
                    <div>{{ data.item.title }}</div>
                    <div>
                      <small>Preview: {{ data.item.preview }}</small>
                    </div>
                  </div>
                </template>
                <template
                  slot="selection"
                  slot-scope="data"
                >
                  <div>
                    <!-- HTML that describe how select should render items when the select is open -->
                    <div>{{ data.item.title }}</div>
                    <div>
                      <small>Preview: {{ data.item.preview }}</small>
                    </div>
                  </div>
                </template>
              </v-select>
            </template>
          </v-card-text>
          <v-divider
            class="mt-4"
            v-if="activeWorkbook !== null && activeSheet !== null"
          />
          <v-card-text v-if="activeWorkbook !== null && activeSheet !== null">
            <div class="mb-3">
              Optional Fields
            </div>
            <template v-for="(optionalField, index) in optionalFields">
              <v-select
                :key="optionalField.property"
                :label="optionalField.title"
                :items="availableValues"
                item-value="key"
                outlined
                hide-details
                class="mb-3"
                clearable
                :disabled="importing"
                v-model="optionalFields[index].value"
                @input="fieldValueChanged"
              >
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <div>
                    <!-- HTML that describe how select should render items when the select is open -->
                    <div>{{ data.item.title }}</div>
                    <div>
                      <small>Preview: {{ data.item.preview }}</small>
                    </div>
                  </div>
                </template>
                <template
                  slot="selection"
                  slot-scope="data"
                >
                  <div>
                    <!-- HTML that describe how select should render items when the select is open -->
                    <div>{{ data.item.title }}</div>
                    <div>
                      <small>Preview: {{ data.item.preview }}</small>
                    </div>
                  </div>
                </template>
              </v-select>
            </template>
          </v-card-text>
          <v-card-text
            class="pt-0 pb-0"
            v-if="activeWorkbook !== null && activeSheet !== null"
          >
            <v-alert
              v-if="importValidationErrors.length > 0"
              color="red"
              dark
              dense
            >
              <small @click="toggleErrors">There are <strong>{{ importValidationErrors.length }}</strong> validation errors. Please fix them to enable importing.</small>
              <div v-if="expandErrors">
                <template v-for="(error, index) in importValidationErrors">
                  <div :key="index">
                    {{ error }}
                  </div>
                </template>
              </div>
            </v-alert>
            <v-alert
              v-if="importValidationErrors.length === 0"
              color="green"
              dark
              dense
            >
              <small>So far your import data is looking good.</small>
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              :disabled="!canImportValues || importing"
              :loading="importing"
              color="primary"
              @click="startImport"
            >
              Import
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="text-center"
          align-self="center"
        >
          <h4 class="text-subtitle-2">
            {{ previewText }}
          </h4>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>

import { read, utils, writeFile } from 'xlsx'
import _ from 'lodash'
export default {
	name: 'GenericImport',
	props: {
		importDataValues: {
			type: Object,
			default: null,
			required: true
		}
	},
	data() {
		return {
			importing: false,
			selectedMode: 'upsert',
			fileToImport: null,
			activeWorkbook: null,
			activeSheet: null,
			activeSheetJSON: null,
			expandErrors: false,
      importErrors: null,
			modes: [
				{
					key: 'upsert',
					title: 'Upsert',
					description: 'Items are updated based on a unique value. Items are created if unique value does not exist.'
				},
				{
					key: 'insert',
					title: 'Insert',
					description: 'New items are created.'
				}
			]
		}
	},
	computed: {
		objectsToCreate() {
			const objects = []

      if(!this.activeSheetJSON || !this.requiredFields) {
        return objects
      }

			for(const row of this.activeSheetJSON) {
				const object = {}

				for(const requiredField of this.requiredFields) {
					_.set(object, requiredField.property, row[requiredField.value])
				}

        if(this.optionalFields) {
          for(const optionalField of this.optionalFields) {
            if(optionalField.value !== null) {
              _.set(object, optionalField.property, row[optionalField.value])
            }
          }
        }

				objects.push(object)
			}

			return objects
		},
		importValidationErrors() {

			const errors = []

			for(let i in this.requiredFields) {
				const requiredField = this.requiredFields[i]

				if(requiredField.value === null || requiredField.value === undefined) {
					errors.push(requiredField.title + ' requires a data column set')
					continue
				}

				switch(requiredField.type) {
					case 'email':
						for(let n in this.objectsToCreate) {
								if(this.objectsToCreate[n][requiredField.property] === null || this.objectsToCreate[n][requiredField.property] === undefined || this.objectsToCreate[n][requiredField.property] === '' || !this.objectsToCreate[n][requiredField.property].includes('@')) {
									errors.push('Row ' + n + ' for value ' + requiredField.title + ' has invalid email')
								}
						}
						break
				}
			}

      if(this.importErrors !== null) {
        errors.push(this.importErrors)
      }

			return errors
		},
		canImportValues() {
			return this.importValidationErrors.length === 0 && this.activeWorkbook !== null && this.activeSheet !== null
		},
		requiredFields() {
			return this.importDataValues.schema.filter(schema => schema.required === true)
		},
		optionalFields() {
			return this.importDataValues.schema.filter(schema => schema.required === false)
		},
		availableValues() {

			const columnsWithValues = Object.keys(this.activeSheetJSON[0])

			const availableColumns = []

			for(let i in columnsWithValues) {
				const columnValue = columnsWithValues[i]
				availableColumns.push({
						column: columnValue,
						key: columnsWithValues[i],
						title: columnsWithValues[i],
						preview: this.activeSheetJSON[0][columnsWithValues[i]]
				})
			}

			return availableColumns
		},
		availableSheets() {
			return this.activeWorkbook.SheetNames
		},
		previewText() {

			if(this.activeWorkbook === null) {
				return 'Start by uploading the file'
			}

			if(this.activeSheet === null) {
				return 'Select a sheet'
			}

			return 'Select appropriate columns for the desired fields'
		}
	},
	methods: {
		toggleErrors() {
			this.expandErrors = !this.expandErrors
		},
		fieldValueChanged() {
      this.importErrors = null
		},
		async startImport() {
			this.importing = true
      let isSuccess = true
			for(const objectToCreate of this.objectsToCreate) {
				try {          
          await this.importDataValues.createCallback(objectToCreate)
        } catch (err) {
          console.error('[GenericImport.startImport] Error importing object:', objectToCreate, err, err.message || 'Unknown error')
          this.importErrors = `Error importing object: ${err.message || 'Unknown error'}`
          isSuccess = false
          break
        }
			}

      this.importing = false
      if(isSuccess) {
        this.$store.commit('updateGenericDialog', null)
      }
		},
		readFileCompleted(result) {
			const workbook = read(result, {})
			this.activeWorkbook = workbook
		},
		readFile(file) {
			let reader = new FileReader()
			reader.readAsArrayBuffer(file)

			reader.onload = () => {
				this.readFileCompleted(reader.result)
			}
		}
	},
	watch: {
		activeSheet() {
			const sheet = this.activeWorkbook.Sheets[this.activeSheet]
			this.activeSheetJSON = utils.sheet_to_json(sheet)
		},
		fileToImport(fileToImport) {

			if(fileToImport !== null) {
				this.readFile(fileToImport)
			}
			else {
				this.activeWorkbook = null
			}
		}
	}
}
</script>
